import api from '@/api';
import { mapFromApi, mapToApi } from '@/mappers';
import type { Applicant, AsyncResult, CompletedLoanApplication, LoanApplication } from '@/types';
import axios from 'axios';
import { computed, ref } from 'vue';
import type { ApplicationDto } from '@/api/backend/users/applications';

class ConversionException extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ConversionException';
  }
}

export function useApplicationApi() {
  const isProcessing = ref(0);
  const submitApplication = async (options: { loanApp: CompletedLoanApplication }): AsyncResult<string> => {
    const { loanApp } = options;
    isProcessing.value++;
    try {
      const request = mapToApi.createApplication(loanApp);
      loanApp.userType === 'Broker'
        ? await api.backend.brokers.applications.submitApplication(request, loanApp.structuralVersionNumber)
        : await api.backend.users.applications.submitApplication(request, loanApp.structuralVersionNumber);
      return { success: true, value: 'Application submitted' };
    } catch (error) {
      console.error(error);
      return { success: false, error: api.getErrorMsg(error) };
    } finally {
      isProcessing.value--;
    }
  };

  const submitApplicant = async (options: {
    applicant: Applicant;
    loanApp: CompletedLoanApplication;
  }): AsyncResult<string> => {
    const { applicant, loanApp } = options;
    isProcessing.value++;
    try {
      const request =
        loanApp.userType === 'Broker'
          ? mapToApi.updateApplication(loanApp)
          : mapToApi.updateApplication(loanApp, applicant || loanApp.applicants[0]);
      const response =
        loanApp.userType === 'Broker'
          ? await api.backend.brokers.applications.submitApplicants({ applicationId: loanApp.applicationId }, request)
          : await api.backend.users.applications.submitApplicant(
              { applicationId: loanApp.applicationId },
              request,
              loanApp.structuralVersionNumber,
            );
      return { success: true, value: response.message };
    } catch (error) {
      console.error(error);
      return { success: false, error: api.getErrorMsg(error) };
    } finally {
      isProcessing.value--;
    }
  };

  const submitApplicants = async (options: {
    applicant: Applicant;
    loanApp: CompletedLoanApplication;
  }): AsyncResult<string> => {
    const { loanApp } = options;
    isProcessing.value++;
    try {
      const request = mapToApi.updateApplication(loanApp);
      const response =
        loanApp.userType === 'Broker'
          ? await api.backend.brokers.applications.submitApplicants({ applicationId: loanApp.applicationId }, request)
          : await api.backend.users.applications.submitApplicants(
              { applicationId: loanApp.applicationId },
              request,
              loanApp.structuralVersionNumber,
            );
      return { success: true, value: response.message };
    } catch (error) {
      console.error(error);
      return { success: false, error: api.getErrorMsg(error) };
    } finally {
      isProcessing.value--;
    }
  };

  const retrieveApplication = async (
    appId: string,
  ): Promise<{
    success: boolean;
    error?: string;
    value?: LoanApplication;
  }> => {
    isProcessing.value++;
    try {
      const response = await api.backend.users.applications.retrieveApplication({ id: appId });
      const application = response.data.application as ApplicationDto;
      const secondaryApplicant = application.applicants?.find((applicant) => applicant.applicantType === 'secondary');
      const secondaryApplicantDeclared = secondaryApplicant?.isApplicantDeclared ?? false;
      try {
        const loanApplication = mapFromApi.application(application);
        loanApplication.isResubmission = true;
        return !secondaryApplicantDeclared
          ? { success: true, value: loanApplication }
          : { success: false, error: 'expired-link' };
      } catch (e) {
        throw new ConversionException('Cannot convert payload into application object');
      }
    } catch (error) {
      if (error instanceof ConversionException) {
        return { success: false, error: 'conversion-fail' };
      } else {
        return { success: false, error: 'network-error' };
      }
    } finally {
      isProcessing.value--;
    }
  };

  const retrieveBrokerApplication = async (
    appId: string,
  ): Promise<{
    success: boolean;
    error?: string;
    value?: LoanApplication;
  }> => {
    isProcessing.value++;
    try {
      const response = await api.backend.brokers.applications.retrieveBrokerApplication({ id: appId });
      const application = response.data.application as ApplicationDto;
      try {
        const loanApplication = mapFromApi.application(application);
        loanApplication.isResubmission = true;
        return { success: true, value: loanApplication };
      } catch (e) {
        throw new ConversionException('Cannot convert payload into application object');
      }
    } catch (error) {
      if (error instanceof ConversionException) {
        return { success: false, error: 'conversion-fail' };
      } else {
        return { success: false, error: 'network-error' };
      }
    } finally {
      isProcessing.value--;
    }
  };

  const backupApplication = async (data: LoanApplication) => {
    try {
      const email = data.applicants[0].email;
      const payload = {
        ...mapToApi.createApplication(data),
        brokerId: data.brokerId ?? '',
        submissionDate: new Date(),
      };
      const json = JSON.stringify(payload);
      const blob = new Blob([json], { type: 'application/json' });
      const file = new File([blob], email, { type: 'application/json' });
      const response = await api.backend.public.backupApplication({
        email,
        contentLength: file.size,
      });
      if (response.data.signedUrl) {
        const signedUrl = response.data.signedUrl;
        if (signedUrl !== 'string') {
          await axios.put(signedUrl, file, { headers: { 'Content-Type': file.type } });
        }
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    isLoading: computed(() => isProcessing.value > 0),
    retrieveApplication,
    retrieveBrokerApplication,
    submitApplication,
    submitApplicant,
    submitApplicants,
    backupApplication,
  };
}
